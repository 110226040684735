import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import { checkResponse } from '@/util/common-utils';
import { notification } from 'ant-design-vue';
import { i18n } from '@/main';
// import { trimValueObj } from '@/util/common-utils';
import { showConfirm } from '@/util/confirm';

const state = () => ({
  loading: false,
  api: ConstantAPI.holiday,
  holidayDetail: {},
  holidayName: '',
  total: 0
});

const actions = {
  async create({state}, payload) {
    const { t } = i18n.global;
    try {
      if (await showConfirm(t('common.confirm_save'))) {
        const response = await DataService.callApi(
          state.api.CREATE,
          payload,
          null
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('common.create_success', {
              name: t('common.holiday'),
            }),
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  async detail({state}, params) {
    try {
      const response = await DataService.callApi(
        state.api.DETAIL,
        null,
        params
      );
      let success;
      checkResponse(response, () => {
        success = true;
        state.holidayDetail = response.data ? response.data : {};
        state.holidayName = response.data.name ? response.data.name : response.data.holidayId; 
      });
      return success;
    } catch (err) {
      console.log(err);
    }
  },
  async update({state}, payload) {
    const { t } = i18n.global;
    try {
      if (await showConfirm(t('common.confirm_save'))) {
        const response = await DataService.callApi(
          state.api.UPDATE,
          payload,
          null
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('common.update_success', {
              name: t('common.holiday'),
            }),
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
