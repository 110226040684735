import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import { checkResponse } from '@/util/common-utils';
import { i18n } from '@/main';
import { notification } from 'ant-design-vue';
import {FORM_MODE, PROFILE_TYPE, REGISTER_STATUS} from '@/util/common-constant';
import dayjs from 'dayjs';

const state = () => ({
  loading: false,
  listUnit: [],
  profile: {},
  registerDetail: null,
  formMode: FORM_MODE.CREATE,
  registerAgainDetail: null,
  listProfileImage: [],
});

const actions = {
  async getAllListUnit({ state }) {
    try {
      const response = await DataService.callApi(ConstantAPI.LIST_UNIT.GET_ALL);
      checkResponse(
        response,
        (res) => {
          state.listUnit = res.content;
        },
        () => (state.listUnit = [])
      );
    } catch (err) {
      console.log(err);
    }
  },
  async create({ commit }, payload) {
    const { t } = i18n.global;
    let isSuccess = false;
    try {
      const response = await DataService.callApi(
        ConstantAPI.REGISTER_IN_OUT.CREATE,
        {
          ...payload,
          imageUrl: payload.imageUrl[0],
          startTime: dayjs(payload.startTime).startOf('minute'),
          endTime: dayjs(payload.endTime).startOf('minute'),
          status: REGISTER_STATUS.CONFIRM
        }
      );
      checkResponse(
        response,
        () => {
          notification.success({
            message: t('common.notification'),
            description: t('register_in_out.register_success'),
            duration: 4,
          });
          isSuccess = true;
        },
        () => {}
      );
      commit('setLoading', false);
      return isSuccess;
    } catch (err) {
      console.log(err);
    }
  },
  async edit({ commit }, payload) {
    const { t } = i18n.global;
    let isSuccess = false;
    try {
      const response = await DataService.callApi(
        ConstantAPI.REGISTER_IN_OUT.UPDATE,
        {
          ...payload,
          imageUrl: payload.imageUrl[0],
          startTime: dayjs(payload.startTime).startOf('minute'),
          endTime: dayjs(payload.endTime).startOf('minute'),
        }
      );
      checkResponse(
        response,
        () => {
          notification.success({
            message: t('common.notification'),
            description: t('register_in_out.edit_success'),
            duration: 4,
          });
          isSuccess = true;
        },
        () => {}
      );
      commit('setLoading', false);
      return isSuccess;
    } catch (err) {
      console.log(err);
    }
  },
  async createProfile({ commit }, payload) {
    const { t } = i18n.global;
    try {
      commit('setLoading', true);
      const nameParts = payload.fullName.split(' ');
      const response = await DataService.callApi(
        ConstantAPI.profileManagement.CREATE,
        {
          ...payload,
          avatar: payload.imageUrl.map((item) => ({ url: item })),
          profileType: PROFILE_TYPE.CUSTOMER,
          workPlace: payload.workUnit,
          lastName: nameParts.slice(0, -1).join(' '),
          firstName: nameParts[nameParts.length - 1],
          unitId: null,
        },
        null
      );
      let profile = null;
      checkResponse(response, (res) => {
        profile = res;
        notification.success({
          message: t('common.notification'),
          description: t('common.create_success', {
            name: t('common.profile'),
          }),
          duration: 4,
        });
      });
      return profile;
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async updateProfile({ commit, state }, payload) {
    const { t } = i18n.global;
    try {
      commit('setLoading', true);
      const nameParts = payload.fullName.split(' ');
      const response = await DataService.callApi(
        ConstantAPI.profileManagement.UPDATE,
        {
          ...payload,
          avatar: state.listProfileImage,
          profileType: PROFILE_TYPE.CUSTOMER,
          workPlace: payload.workUnit,
          lastName: nameParts.slice(0, -1).join(' '),
          firstName: nameParts[nameParts.length - 1],
          id: payload.profileId,
        },
        null
      );
      let profile = null;
      checkResponse(response, (res) => {
        profile = res.id;
        notification.success({
          message: t('common.notification'),
          description: t('common.update_success', {
            name: t('common.profile'),
          }),
          duration: 4,
        });
      });
      return profile;
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async searchProfile({ commit, state }, params) {
    try {
      commit('setLoading', true);
      const response = await DataService.callApi(
        ConstantAPI.profileManagement.GET_ALL,
        null,
        { imgUrl: params, profileType: PROFILE_TYPE.CUSTOMER }
      );
      let isSuccess = false;
      checkResponse(response, (res) => {
        isSuccess = true;
        state.profile = res.content ? res.content[0] : null;
      });
      return isSuccess;
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async getDetailProfile({ state }, id) {
    const response = await DataService.callApi({
      url: ConstantAPI.profileManagement.GET_BY_IDS.url + id,
      method: ConstantAPI.profileManagement.GET_BY_IDS.method,
    });
    checkResponse(
      response,
      (res) => {
        state.listProfileImage = res.avatar;
      },
      () => {
        state.listProfileImage = [];
      }
    );
  },
  getRegisterDetail({ state }, { record, mode }) {
    state.registerDetail = { ...record, imageUrl: [record.imageUrl] };
    state.formMode = mode;
  },
  setFormMode({ state }, mode) {
    state.formMode = mode;
  },
  setRegisterAgainDetail({ state }, detail) {
    state.registerAgainDetail = detail;
  },
  onResetForm({ state }) {
    state.registerDetail = null;
    state.formMode = FORM_MODE.CREATE;
    state.listProfileImage = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
