export const FORM_MODE = {
  CREATE: 1,
  UPDATE: 2,
  VIEW: 3,
};

export const GENDERS = [
  {
    value: 'M',
    label: 'common.male',
  },
  {
    value: 'F',
    label: 'common.female',
  },
];

export const STATUS = [
  {
    value: 1,
    label: 'common.active',
  },
  {
    value: 0,
    label: 'common.inactive',
  },
];
export const IS_PUBLIC_STATUS = [
  {
    value: 1,
    label: 'Hiển thị',
  },
  {
    value: 0,
    label: 'Không hiển thị',
  },
];

export const OBJECT_QA = [
  {
    value: 1,
    label: 'Cá nhân',
  },
  {
    value: 2,
    label: 'Tổ chức',
  },
  {
    value: 3,
    label: 'Tổng hợp',
  },
];
export const STATUS_APPROVAL = [
  {
    value: 1,
    label: 'Đã duyệt',
  },
  {
    value: 0,
    label: 'Chưa duyệt',
  },
  {
    value: -1,
    label: 'Từ chối',
  },
];

export const ARTICLE_STATUS_BAN_NHAP = 1;
export const ARTICLE_STATUS_GUI_DUYET = 2;
export const ARTICLE_STATUS_BIEN_TAP_LAI = 3;
export const ARTICLE_STATUS_CHO_XUAT_BAN = 4;
export const ARTICLE_STATUS_DUYET_LAI = 5;
export const ARTICLE_STATUS_XUAT_BAN = 6;
export const ARTICLE_STATUS_GO_BAI = 7;
export const STATUS_ARTICLE = [
  {
    value: ARTICLE_STATUS_BAN_NHAP,
    label: 'Bản nháp',
  },
  {
    value: ARTICLE_STATUS_GUI_DUYET,
    label: 'Gửi duyệt',
  },
  {
    value: ARTICLE_STATUS_BIEN_TAP_LAI,
    label: 'Biên tập lại ',
  },
  {
    value: ARTICLE_STATUS_CHO_XUAT_BAN,
    label: 'Chờ xuất bản',
  },
  {
    value: ARTICLE_STATUS_DUYET_LAI,
    label: 'Duyệt lại',
  },
  {
    value: ARTICLE_STATUS_XUAT_BAN,
    label: 'Xuất bản',
  },
  {
    value: ARTICLE_STATUS_GO_BAI,
    label: 'Gỡ bài',
  },
];
export const STATUS_ARTICLE_COLOR = [
  {
    value: ARTICLE_STATUS_BAN_NHAP,
    label: '#9196a4',
  },
  {
    value: ARTICLE_STATUS_GUI_DUYET,
    label: '#dcd724',
  },
  {
    value: ARTICLE_STATUS_BIEN_TAP_LAI,
    label: '#d966a0',
  },
  {
    value: ARTICLE_STATUS_CHO_XUAT_BAN,
    label: '#6941a4',
  },
  {
    value: ARTICLE_STATUS_DUYET_LAI,
    label: '#d0095a',
  },
  {
    value: ARTICLE_STATUS_XUAT_BAN,
    label: '#269322',
  },
  {
    value: ARTICLE_STATUS_GO_BAI,
    label: '#ccbfb1',
  },
];

export const AGENCY_TYPE = [
  {
    value: 1,
    label: 'Cơ quan',
  },
  {
    value: 2,
    label: 'Nhà mạng',
  },
  {
    value: 3,
    label: 'Bank',
  },
];

export const AGENCY_PROCESS = [
  {
    value: 0,
    label: 'Chưa có thông tin',
  },
  {
    value: 1,
    label: 'Chưa thực hiện',
  },
  {
    value: 2,
    label: 'Đang thực hiện',
  },
  {
    value: 3,
    label: 'Đã thực hiện',
  },
];

export const defaultButton = (menu) => {
  const buttonsForm = [
    { type: 'RESET' },
    // { type: 'SEARCH' },
    { type: 'CREATE', permission: `${menu}:create` },
    { type: 'DELETE', permission: `${menu}:delete` },
  ];
  const buttonActions = [
    { type: 'EDIT', permission: `${menu}:edit` },
    { type: 'DELETE', permission: `${menu}:delete` },
  ];
  return { buttonsForm, buttonActions };
};

export const defaultButtonApproval = () => {
  const buttonsForm = [{ type: 'RESET' }, { type: 'SEARCH' }];
  const buttonActions = [{ type: 'APPROVAL' }, { type: 'CANCEL_APPROVAL' }];
  return { buttonsForm, buttonActions };
};
export const defaultButtonConfigAgency = () => {
  const buttonsForm = [{ type: 'RESET' }, { type: 'SEARCH' }];
  const buttonActions = [{ type: 'SHOW' }, { type: 'HIDDEN' }];
  return { buttonsForm, buttonActions };
};
export const HIDDEN_MENUS = ['/system/agency/detail'];
export const STATUS_AGENCY = {
  show: 1,
  hidden: 0,
};
export const STATUS_AGENCY_TEXT = [
  {
    value: 1,
    label: 'Hiển thị',
  },
  {
    value: 0,
    label: 'Ẩn',
  },
];

export const LANGUAGE_TYPE = [
  {
    value: 'vi',
    label: 'Tiếng Việt',
  },
  {
    value: 'en',
    label: 'English',
  },
];

export const MEDIA_TYPE = [
  {
    value: 2,
    label: 'Ảnh',
  },
  {
    value: 4,
    label: 'Video',
  },
];
export const TYPE_BANNER = [
  {
    value: 1,
    label: 'Đầu trang',
  },
  {
    value: 2,
    label: 'Thân trang',
  },
];

export const TYPE_AGENCY = [
  {
    value: 1,
    label: 'Cơ quan ban hành',
  },
  {
    value: 2,
    label: 'Cơ quan tiếp nhận',
  },
];

export const APP_PARAM_TYPE = {
  TYPE_OF_WORK: 'type_of_work',
  EXPERIENCE: 'experience',
  EMPLOYEE_LEVEL: 'employee_level',
  WORKPLACE: 'workplace',
};

export const LANGUAGE = {
  VIETNAMESE: 'vi',
  ENGLISH: 'en',
};

export const CONTACT_READ = 1;
export const CONTACT_UNREAD = 0;
export const STATUS_CONTACT = [
  {
    value: CONTACT_READ,
    label: 'Đã xem',
  },
  {
    value: CONTACT_UNREAD,
    label: 'Mới',
  },
];
export const UNIT_TYPE = {
  DON_VI: 1,
  PHONG_BAN: 2,
};
export const NAVIGATION_TYPE = {
  PREV: -1,
  NEXT: 1,
};

export const TAB_KEY = {
  INFO: 'info',
  CAMERA: 'camera',
  HISTORY: 'history',
};

export const TAB_KEY_DASHBOARD = {
  DASHBOARD: 'dashboard',
  CUSTOMER: 'customer',
  STRANGER: 'stranger',
};

export const PROFILE_TYPE = {
  EMPLOYEE: 'NV',
  CUSTOMER: 'KH',
  VIP: 'VIP',
  BLACKLIST: 'BL',
  STRANGER: 'LA',
};

export const PROFILE_TYPE_OPTIONS = [
  { value: 'NV', label: 'profile.form.employee' },
  { value: 'KH', label: 'profile.form.customer' },
  { value: 'VIP', label: 'profile.form.VIP' },
  { value: 'BL', label: 'profile.form.blacklist' },
];
export const PROFILE_TYPE_OPTIONS_1 = [
  { value: 'NV', label: 'profile.form.employee' },
  { value: 'KH', label: 'profile.form.customer' },
  { value: 'VIP', label: 'profile.form.VIP' },
  { value: 'LA', label: 'profile.form.stranger' },
  { value: 'BL', label: 'profile.form.blacklist' },
];

export const IDENTITY_CARD_TYPE = [
  { value: '1', label: 'profile.form.cmt' },
  { value: '2', label: 'profile.form.cccd' },
  { value: '3', label: 'profile.form.passport' },
];
export const PROFILE_TYPE_COLOR = {
  NV: {
    text: 'event.staff',
    type: 'primary',
    color: 'bg-hrm-main1 text-hrm-white',
  },
  LA: {
    text: 'event.guest',
    type: 'info',
    color: 'bg-hrm-hong text-hrm-white',
  },
  KH: {
    text: 'event.customer',
    type: 'warning',
    color: 'bg-hrm-main3 text-hrm-white',
  },
  VIP: {
    text: 'event.vip',
    type: 'warning',
    color: 'bg-hrm-main3 text-hrm-white',
  },
  BL: {
    text: 'profile.form.blacklist',
    type: 'dark',
    color: 'bg-hrm-gray800 text-hrm-white',
  },
};
export const EVENT_STATUS = {
  XUAT_HIEN: {
    text: 'event.appear',
    type: 'info',
    color: 'text-hrm-coBan border-hrm-coBan',
  },
  ZONE_ACCESS_CONTROL_OUT: {
    text: 'event.checkout',
    type: 'error',
    color: 'text-hrm-vang700 border-hrm-vang700',
  },
  ZONE_ACCESS_CONTROL_IN: {
    text: 'event.checkin',
    type: 'warning',
    color: 'text-hrm-main3 border-hrm-main3',
  },
  ZONE_INTRUSION: {
    text: 'event.intrusion',
    type: 'link',
    color: 'text-hrm-hong border-hrm-hong',
  },
};
export const EVENT_STATUS_OPTION = [
  { value: 'ZONE_ACCESS_CONTROL_OUT', label: 'event.checkout' },
  { value: 'ZONE_ACCESS_CONTROL_IN', label: 'event.checkin' },
];

export const EVENT_DOOR_STATUS_OPTION = [
  { value: 'ZONE_ACCESS_CONTROL_OUT', label: 'event.ZONE_ACCESS_CONTROL_OUT' },
  { value: 'ZONE_ACCESS_CONTROL_IN', label: 'event.ZONE_ACCESS_CONTROL_IN' },
];

export const USER_POSITION = {
  GPS: 1,
  DOI_TAC: 2,
  NHAN_VIEN: 4,
};
export const GENDER = {
  M: 'common.male',
  F: 'common.female',
};
export const REGISTER_INOUT_STATUS = [
  { label: 'register_in_out.new_register', value: 3 },
  { label: 'register_in_out.meeting', value: 4 },
  { label: 'register_in_out.complete', value: 5 },
];

export const REGISTER_STATUS = {
  NO_CONFIRM: 1,
  REFUSE: 2,
  CONFIRM: 3,
  IN_PROGRESS: 4,
  COMPLETE: 5,
};

export const ATTENDANCE_TYPE = {
  ATTENDANCE: 'StatusAttended',
  NOT_ATTENDANCE: 'StatusNotAttended',
};
export const ATTENDANCE_TYPE_OPTIONS = [
  {
    label: 'common.StatusAttended',
    value: 'StatusAttended',
  },
  {
    label: 'common.StatusNotAttended',
    value: 'StatusNotAttended',
  },
];

export const TIME_SELECT = [
  { label: 'access_control.month', value: 'M' },
  { label: 'access_control.week', value: 'W' },
  { label: 'access_control.day', value: 'D' },
];

export const STATISTICAL_OBJECT = {
  YEAR: 'Y',
  QUARTER: 'Q',
  MONTH: 'M',
  WEEK: 'W',
  DAY: 'D',
  HOUR: 'H',
};

export const EVENT_TYPE = {
  1: {
    value: 1,
    text: 'event.normal',
    color: 'text-hrm-main3',
  },
  2: {
    value: 2,
    text: 'event.warning',
    color: 'text-hrm-main2',
  },
};
export const EVENT_TYPE_OPTIONS = [
  { label: 'event.normal', value: 1 },
  { label: 'event.warning', value: 2 },
];

export const DATE_TYPE = {
  YEAR: 'Y',
  MONTH: 'M',
  WEEK: 'W',
  DAY: 'D',
  TIME: 'T',
};

export const DATE_TYPE_OPTIONS = [
  { label: 'common.year', value: 'Y' },
  { label: 'common.month', value: 'M' },
  { label: 'common.week', value: 'W' },
  { label: 'common.day', value: 'D' },
  { label: 'common.time', value: 'T' },
];

export const LIST_PURPOSE = [
  { value: '1', label: 'register_in_out.appointment' },
  { value: '2', label: 'register_in_out.recruitment' },
  { value: '3', label: 'register_in_out.contract' },
  { value: '4', label: 'register_in_out.visit' },
  { value: '5', label: 'register_in_out.training' },
  { value: '6', label: 'register_in_out.repair' },
  { value: '7', label: 'register_in_out.inspection' },
  { value: '8', label: 'register_in_out.health_care' },
  { value: '9', label: 'register_in_out.utility' },
  { value: '10', label: 'register_in_out.others' },
];

export const LANE_CONTROL_TYPE_OPTION = [
  { value: 'ZONE_ACCESS_CONTROL_OUT', label: 'event.ZONE_ACCESS_CONTROL_OUT' },
  { value: 'ZONE_ACCESS_CONTROL_IN', label: 'event.ZONE_ACCESS_CONTROL_IN' },
];
export const LANE_CONTROL_TYPE = {
  ZONE_ACCESS_CONTROL_OUT: {
    text: 'event.ZONE_ACCESS_CONTROL_OUT',
    type: 'link',
    color: 'text-hrm-coBan',
  },
  ZONE_ACCESS_CONTROL_IN: {
    text: 'event.ZONE_ACCESS_CONTROL_IN',
    type: 'warning',
    color: 'text-hrm-main3',
  },
};
export const LANE_CONTROL_MOVE_OPTION = [
  { value: 0, label: 'event.is-move' },
  { value: 1, label: 'event.not-move' },
];

export const RESPONSE_TYPE = {
  IN_OUT: 1,
  DOOR: 2,
  INTRUSION: 3,
};

export const RESPONSE_TYPE_OPTIONS = [
  { value: 'in-out', label: 'event.in-out' },
  { value: 'lane-control', label: 'event.open-door' },
  { value: 'intrusion', label: 'event.intrusion-event' },
  { value: 'monitor', label: 'menu_manager.monitor-event' },
];

export const EVENT = [
  { value: 1, label: 'event.in_out' },
  { value: 2, label: 'event.open_door' },
  { value: 3, label: 'event.intrusion' },
];

export const EVENT_CODE = {
  IN_OUT: 'in-out',
  OPEN_DOOR: 'lane-control',
  INTRUSION: 'intrusion',
};

export const RACE = {
  white: 'human.white',
  black: 'human.black',
  latino_hispanic: 'human.latino_hispanic',
  east_asian: 'human.east_asian',
  southeast_asian: 'human.southeast_asian',
  indian: 'human.indian',
  middle_eastern: 'human.middle_eastern',
};
export const GENDER_EVENT = {
  male: 'common.male',
  female: 'common.female',
};
export const TYPESTAFF = {
  Y: 1,
  N: 0,
};
export const BASE_WORKING_TIME = {
  BY_HOUR: 'byHour',
  BY_TIME: 'byDate',
};
export const ALLOW_OR_NOT = {
  ALLOW: 'allow',
  NOT_ALLOW: 'notAllow',
};
export const TIMEKEEPING_TYPE = {
  BY_TIME: 'byTime',
  BEFORE_TIME: 'beforeTime',
};
export const YES_OR_NO = {
  YES: 'yes',
  NO: 'no',
};

export const STATUS_OPTIONS = [
  { label: 'common.active', value: 1 },
  { label: 'common.inactive', value: 0 },
];

export const PERMIT_STATUS_OPTIONS = [
  { label: 'leave_application.pending_approval', value: 1 },
  { label: 'common.approval', value: 2 },
  { label: 'common.disapprove', value: 3 },
];

export const PERMIT_TYPE_OPTIONS = [
  { label: 'leave_application.leave', value: 'AbsenceRequest' },
  { label: 'leave_application.be_late', value: 'LateRequest' },
  { label: 'leave_application.come_back_early', value: 'EarlyExitRequest' },
];

export const DATE_TYPE_STATIC = [
  { label: 'common.year', value: 'Y' },
  { label: 'common.quarter', value: 'Q' },
  { label: 'common.month', value: 'M' },
  { label: 'common.week', value: 'W' },
  { label: 'common.day', value: 'D' },
];
export const CAMERA_STATUS = {
  DISCONNECTED: 'DISCONNECTED',
  CONNECTED: 'CONNECTED',
};

export const EVENT_DOOR = {
  OUT: 'ZONE_ACCESS_CONTROL_OUT',
  IN: 'ZONE_ACCESS_CONTROL_IN',
};

export const OCCUPATION_OPTION = [
  { label: 'common.staff', value: 'Staff' },
  { label: 'common.director', value: 'Director' },
  { label: 'common.manager', value: 'Manager' },
];

export const OCCUPATIONS = {
  Staff: 'common.staff',
  Manager: 'common.manager',
  Director: 'common.director',
};

export const ALERT_STATUS_OPTIONS = [
  { label: 'common.active', value: true },
  { label: 'common.inactive', value: false },
];

export const ALERT_LOCATION_SHOW = [
  { label: 'common.active', value: 'bottomLeft' },
  { label: 'common.inactive', value: 'bottomRight' },
];

export const CLASSIFY_PROFILE_OPTIONS = [
  { label: 'common.human', value: 'HUMAN' },
  { label: 'common.vehicle', value: 'VEHICLE' },
];
export const CLASSIFY_PROFILE_VALUE = {
  HUMAN: 'HUMAN',
  VEHICLE: 'VEHICLE',
};

export const TIME_SELECT_OPTIONS = [
  { label: 'access_control.month', value: 'M' },
  { label: 'access_control.week', value: 'W' },
  { label: 'access_control.day', value: 'D' },
];

export const LIST_SOUND_ALERT = [
  { label: 'Bell', value: 'bell' },
  { label: 'Level up', value: 'level-up' },
  { label: 'Live chat', value: 'livechat' },
  { label: 'Warning', value: 'warning' },
];

export const LIST_LOCATION_ALERT = [
  { label: 'alert_configuration.top_of_the_screen', value: 'topRight' },
  { label: 'alert_configuration.bottom_of_the_screen', value: 'bottomRight' },
];

export const ATTENDANCE_STATE = {
  ON_TIME: 'StateOnTime',
  LATED: 'StateLated',
  EXIT_EARLY: 'StateExitEarly',
  NOT_ATTENDED: 'StateNotAttended',
  AUTHORIZED_ABSENCE: 'StateAuthorizedAbsence',
  UNAUTHORIZED_ABSENCE: 'StateUnauthorizedAbsence',
};

export const COMMA = ',';
export const PERCENT_IMPORT_PROFILE = 'percentImportProfile';

export const DEVICE_TYPE = {
  BOX: 'AI_BOX',
  DOOR: 'DOOR',
  CAMERA: 'CAMERA',
};
export const AI_FLOWS = [
  {
    label: 'Bài toán check-in',
    value: 'ZONE_ACCESS_CONTROL_IN',
  },
  {
    label: 'Bài toán check-out',
    value: 'ZONE_ACCESS_CONTROL_OUT',
  },
];
export const STATE_CAMERA = {
  CONNECTING: 'Kết nối',
  DISCONNECTED: 'Chưa kết nối',
};

export const EVENTS = {
  IN_OUT: 'in-out',
  LANE_CONTROL: 'lane-control',
  INTRUSION: 'intrusion',
  MONITOR: 'monitor',
};

export const SYSTEM_TYPE = {
  WEB: 1,
  APP: 2,
};

export const LEAVE_STATUS = {
  PENDING: 1,
  APPROVE: 2,
  DISAPPROVE: 3,
};
export const TYPE_AI_FLOW = {
  HUMAN_INTRUSION: 'HUMAN_INTRUSION',
  HUMAN_FLOW: 'HUMAN_FLOW',
  HUMAN_ACCESS_CONTROL: 'HUMAN_ACCESS_CONTROL',
  HUMAN_WEAPON_DETECT: 'HUMAN_WEAPON_DETECT',
  HUMAN_FEATURE: 'HUMAN_FEATURE',
  VEHICLE_INTRUSION: 'VEHICLE_INTRUSION',
  VEHICLE_FLOW: 'VEHICLE_FLOW',
  VEHICLE_ACCESS_CONTROL: 'VEHICLE_ACCESS_CONTROL',
  VEHICLE_WEAPON_DETECT: 'VEHICLE_WEAPON_DETECT',
  VEHICLE_FEATURE: 'VEHICLE_FEATURE',
};
export const KEY_AI_FLOW = {
  humanIntrusionCount: 'humanIntrusionCount',
  humanFlowCount: 'humanFlowCount',
  humanAccessControlCount: 'humanAccessControlCount',
  humanWeaponDetectCount: 'humanWeaponDetectCount',
  humanFeatureCount: 'humanFeatureCount',
  vehicleIntrusionCount: 'vehicleIntrusionCount',
  vehicleFlowCount: 'vehicleFlowCount',
  vehicleAccessControlCount: 'vehicleAccessControlCount',
  vehicleWeaponDetectCount: 'vehicleWeaponDetectCount',
  vehicleFeatureCount: 'vehicleFeatureCount',
  humanCount: 'humanCount',
  vehicleCount: 'vehicleCount',
};
export const TYPE_AI_OBJECT = {
  HUMAN: 'HUMAN',
  VEHICLE: 'VEHICLE',
};
export const ipRegex =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

export const TYPE_DOOR = [
  { label: 'device.door-a', value: 'device.door-a' },
  { label: 'device.door-b', value: 'device.door-b' },
  { label: 'device.door-c', value: 'device.door-c' },
  { label: 'device.door-d', value: 'device.door-d' },
  { label: 'device.door-e', value: 'device.door-e' },
  { label: 'device.door-f', value: 'device.door-f' },
  { label: 'device.door-g', value: 'device.door-g' },
  { label: 'device.other', value: 'device.other' },
];
export const TYPE_BRAND = [
  { label: 'ZKTeco', value: 'ZKTeco' },
  { label: 'Bisen', value: 'Bisen' },
  { label: 'Sewo', value: 'Sewo' },
  { label: 'JCA', value: 'JCA' },
  { label: 'Rona', value: 'Rona' },
  { label: 'Realand', value: 'Realand' },
  { label: 'Centuryfair', value: 'Centuryfair' },
  { label: 'Keyable', value: 'Keyable' },
  { label: 'Shenzhen', value: 'Shenzhen' },
  { label: 'Turboo', value: 'Turboo' },
  { label: 'Fujica', value: 'Fujica' },
  { label: 'Kaba', value: 'Kaba' },
  { label: 'Hikvision', value: 'Hikvision' },
  { label: 'Ambon', value: 'Ambon' },
  { label: 'Tênt', value: 'Tênt' },
  { label: 'IDTeck', value: 'IDTeck' },
  { label: 'TDZ Security', value: 'TDZ Security' },
  { label: 'device.other', value: 'device.other' },
];
export const STREAM = [
  {
    label: 'device.normal',
    value: false,
  },
  {
    label: 'device.ai',
    value: true,
  },
];
export const PERMISSION_DEVICES = {
  VIEW_CAMERA_GROUP: 'VIEW_CAMERA_GROUP',
  CREATE_CAMERA_GROUP: 'CREATE_CAMERA_GROUP',
  EDIT_CAMERA_GROUP: 'EDIT_CAMERA_GROUP',
  DELETE_CAMERA_GROUP: 'DELETE_CAMERA_GROUP',
  VIEW_CAMERA: 'VIEW_CAMERA',
  CREATE_CAMERA: 'CREATE_CAMERA',
  EDIT_CAMERA: 'EDIT_CAMERA',
  DELETE_CAMERA: 'DELETE_CAMERA',
  ASSIGN_CAMERA_TO_GROUP: 'ASSIGN_CAMERA_TO_GROUP',
  VIEW_CAMERA_IN_GROUP: 'VIEW_CAMERA_IN_GROUP',
  VIEW_AIFLOW: 'VIEW_AIFLOW',
  CREATE_AIFLOW: 'CREATE_AIFLOW',
  EDIT_AIFLOW: 'EDIT_AIFLOW',
  DELETE_AIFLOW: 'DELETE_AIFLOW',
  VMS_ADMIN: 'VMS_ADMIN',
};

export const TAB_KEY_NOTIFICATION = {
  SUMMARY: 'summary',
  PERSONAL: 'personal',
};

export const CONFIG_MAIL_MONTH_OPTIONS = [
  { label: 'common.out_of_month', value: 2 },
  { label: 'common.in_the_month', value: 1 },
];

export const humanAI = [
  {
    name: 'device.intrusion',
    key: KEY_AI_FLOW.humanIntrusionCount,
    active: false,
    type: TYPE_AI_FLOW.HUMAN_INTRUSION,
  },
  {
    name: 'device.flow',
    key: KEY_AI_FLOW.humanFlowCount,
    active: false,
    type: TYPE_AI_FLOW.HUMAN_FLOW,
  },
  {
    name: 'device.access_control',
    key: KEY_AI_FLOW.humanAccessControlCount,
    active: false,
    type: TYPE_AI_FLOW.HUMAN_ACCESS_CONTROL,
  },
  {
    name: 'device.weapon_detect',
    key: KEY_AI_FLOW.humanWeaponDetectCount,
    active: false,
    type: TYPE_AI_FLOW.HUMAN_WEAPON_DETECT,
  },
  {
    name: 'device.feature',
    key: KEY_AI_FLOW.humanFeatureCount,
    active: false,
    type: TYPE_AI_FLOW.HUMAN_FEATURE,
  },
];
export const vehicleAI = [
  {
    name: 'device.intrusion',
    key: KEY_AI_FLOW.vehicleIntrusionCount,
    active: false,
    type: TYPE_AI_FLOW.VEHICLE_INTRUSION,
  },
  {
    name: 'device.flow',
    key: KEY_AI_FLOW.vehicleFlowCount,
    active: false,
    type: TYPE_AI_FLOW.VEHICLE_FLOW,
  },
  {
    name: 'device.access_control',
    key: KEY_AI_FLOW.vehicleAccessControlCount,
    active: false,
    type: TYPE_AI_FLOW.VEHICLE_ACCESS_CONTROL,
  },
  {
    name: 'device.feature',
    key: KEY_AI_FLOW.vehicleFeatureCount,
    active: false,
    type: TYPE_AI_FLOW.VEHICLE_FEATURE,
  },
];
export const dataDefault = [
  {
    id: 1,
    name: 'device.human',
    icon: require('@/static/img/device/person.svg'),
    total: 0,
    Ai: humanAI,
    key: TYPE_AI_OBJECT.HUMAN,
  },
  {
    id: 2,
    name: 'device.vehicle',
    icon: require('@/static/img/device/car.svg'),
    total: 0,
    Ai: vehicleAI,
    key: TYPE_AI_OBJECT.VEHICLE,
  },
];

export const OPTIONS_DEVICE_TYPE = [
  {
    label:'AIBOX',
    value:'AI_BOX'
  },
  {
    label:'DOOR',
    value:'DOOR'
  },
  {
    label:'CAMERA',
    value:'CAMERA'
  }
]
export const NOTIFY_CHANNEL = [
  { label: 'email_config.telegram', value: 'telegram' },
  { label: 'email_config.gmail', value: 'gmail' },
  { label: 'email_config.mobile_app', value: 'mobileApp' },
]

export const SOCKET_TYPE = {
  EVENT: 'event',
  NOTIFY: 'notify'
}
