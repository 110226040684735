import mutations from './mutations';
import { getField, updateField } from 'vuex-map-fields';
import { i18n } from '@/main';
import { DataService } from '@/dataService/dataService';
import ConstantAPI from '@/config/ConstantAPI';
import { checkResponse } from '@/util/common-utils';
import { notification } from 'ant-design-vue';
import { FORM_MODE } from '@/util/common-constant';

const state = () => ({
  formMode: FORM_MODE.CREATE,
  partnerDetail: {},
  partnerMenu: [],
  partnerMenuName: [],
  partnerMenuApp: [],
  partnerMenuNameApp: [],
  loading: false,
  partnerInfo: {},
});

const actions = {
  async create({ commit }, payload) {
    const { t } = i18n.global;
    let isSuccess = false;
    try {
      commit('setLoading', true);
      const response = await DataService.callApi(ConstantAPI.PARTNER.CREATE, {
        ...payload,
        roleMenus: payload.roleMenus.map((e) => ({ menuId: e.value })),
        appMenus: payload.appMenus.map((e) => ({ menuId: e.value })),
      });
      checkResponse(
        response,
        () => {
          notification.success({
            message: t('common.notification'),
            description: t('partner.create_success'),
            duration: 4,
          });
          isSuccess = true;
        },
      );
      commit('setLoading', false);
      return isSuccess;
    } catch (err) {
      console.log(err);
    }
  },
  async update({ commit }, payload) {
    const { t } = i18n.global;
    let isSuccess = false;
    try {
      commit('setLoading', true);
      const response = await DataService.callApi(ConstantAPI.PARTNER.UPDATE, {
        ...payload,
        roleMenus: payload.roleMenus.map((e) => ({ menuId: e.value })),
        appMenus: payload.appMenus.map((e) => ({ menuId: e.value })),
      });
      checkResponse(
        response,
        () => {
          notification.success({
            message: t('common.notification'),
            description: t('partner.update_success'),
            duration: 4,
          });
          isSuccess = true;
        },
        () => {}
      );
      commit('setLoading', false);
      return isSuccess;
    } catch (err) {
      console.log(err);
    }
  },
  async getById({ commit, state }, partnerId) {
    let isSuccess = false;
    try {
      const response = await DataService.callApi(
        ConstantAPI.PARTNER.GET_BY_ID,
        null,
        { partnerId }
      );
      checkResponse(
        response,
        (res) => {
          state.partnerDetail = res;
          isSuccess = true;
        },
        () => {}
      );
      commit('setLoading', false);
      return isSuccess;
    } catch (err) {
      console.log(err);
    }
  },
  async getInfo({ commit, state }) {
    let isSuccess = false;
    try {
      const response = await DataService.callApi(ConstantAPI.PARTNER.GET_INFO);
      checkResponse(
        response,
        (res) => {
          state.partnerInfo = res;
          isSuccess = true;
        },
        () => {}
      );
      commit('setLoading', false);
      return isSuccess;
    } catch (err) {
      console.log(err);
    }
  },
  async getPartnerMenu({ commit, state }, partnerId) {
    let isSuccess = false;
    try {
      const response = await DataService.callApi(
        ConstantAPI.PARTNER.GET_MENU,
        null,
        { partnerId }
      );
      checkResponse(
        response,
        (res) => {
          state.partnerMenu = res['web'].map((item) => ({
            value: item.menuId,
            label: item.menuKey
          }));
          state.partnerMenuApp = res['app'].map((item) => ({
            value: item.menuId,
            label: item.menuKey
          }));
          state.partnerMenuName = res['web'].map((item) => item.menuKey);
          state.partnerMenuNameApp = res['app'].map((item) => item.menuKey);
          isSuccess = true;
        },
        () => {}
      );
      commit('setLoading', false);
      return isSuccess;
    } catch (err) {
      console.log(err);
    }
  },
  async delete({ commit }, rows) {
    const { t } = i18n.global;
    let isSuccess = false;
    try {
      const response = await DataService.callApi(ConstantAPI.PARTNER.DELETE, {
        partnerId: rows.map((item) => item.partnerId).join(','),
      });
      checkResponse(
        response,
        () => {
          notification.success({
            message: t('common.notification'),
            description: t('partner.delete_success'),
            duration: 4,
          });
          isSuccess = true;
        },
        () => {}
      );
      commit('setLoading', false);
      return isSuccess;
    } catch (err) {
      console.log(err);
    }
  },
  async resetPassword({ state }, id) {
    const { t } = i18n.global;
    const response = await DataService.callApi({
      url: ConstantAPI.PARTNER.RESET_PARTNER_PASSWORD.url + id,
      method: ConstantAPI.PARTNER.RESET_PARTNER_PASSWORD.method,
    });
    checkResponse(
      response,
      () => {
        notification.success({
          message: t('common.notification'),
          description: t('user.reset_password_success'),
          duration: 4,
        });
      },
      () => {
        state.profileName = null;
      }
    );
  },
  setFormMode({ state }, mode) {
    state.formMode = mode;
  },
  resetForm({ state }) {
    state.partnerDetail = {};
    state.partnerMenu = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
